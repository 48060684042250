import React, { useEffect, useState } from "react";
import axios from "axios";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import moment from "moment";
import { Button } from "@mui/material";
import Header from "./Header";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { Form, Toast } from "react-bootstrap";

const endPoints = "https://api.gdswellness.com";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "80vh",
  overflowY: "auto",
  textAlign: "justify",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function Order() {
  const navigate = useNavigate();

  const [orderData, setOrderData] = useState([]);
  const [SelectedData, setSelectedData] = useState([]);

  const [SDate, setSDate] = useState({
    start: "",
    end: "",
  });

  const handleChange = e => {
    const { name, value } = e.target;
    setSDate(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [Preview, setPreview] = useState(false);

  const columns = [
    { name: "Order ID", selector: row => row.OrderId },
    { name: "Amount", selector: row => <p>${row.amount}</p> },
    { name: "Status", selector: row => <p>{row.status}</p> },
    {
      name: "Date",
      selector: row => moment(row.createdAt).format("DD-MM-YY"),
    },
    {
      name: "Action",
      cell: row => (
        <>
          <p
            style={{ cursor: "pointer", marginRight: "15px", color: "red" }}
            onClick={() => handleDeleteSingle(row._id)}>
            Delete
          </p>
          <p
            style={{ cursor: "pointer", marginRight: "5px", color: "skyblue" }}
            onClick={() => handleView(row)}>
            View
          </p>

        </>
      ),
    },
  ];


  const [status, setStatus] = useState('');
  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleUpdateStatus = async () => {
    try {
      let allSuccessful = true;

      for (const data of SelectedData) {
        try {
          const res = await axios.put(`${endPoints}/api/order/update/${data._id}`, { status });
          if (res.status !== 200) {
            allSuccessful = false;
            console.error(`Failed to update order ${data._id}`);
          }
        } catch (error) {
          allSuccessful = false;
          console.error(`Error updating order ${data._id}:`, error);
        }
      }

      if (allSuccessful) {
        alert('All orders updated successfully');
        window.location.reload("")
      } else {
        alert('Some orders could not be updated');
      }
    } catch (error) {
      console.error('Error updating orders:', error);
      alert('An error occurred while updating orders');
    }
  };





  const handleView = row => {
    navigate(`/orderview/${row.OrderId}`, { state: { id: row._id } });
  };


  const handleDeleteSingle = async id => {
    if (window.confirm("Are you sure you want to delete this order?")) {
      try {
        let response = await axios.post(`${endPoints}/api/order/trash/${id}`);
        if (response.status === 200) {
          setOrderData(orderData.filter(item => item._id !== id));
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete selected orders?")) {
      try {
        let deletedCount = 0;
        for (let data of SelectedData) {
          let response = await axios.post(
            `${endPoints}/api/order/trash/${data._id}`
          );
          if (response.status === 200) {
            deletedCount++;
          }
        }
        setOrderData(orderData.filter(item => !SelectedData.includes(item)));
        setSelectedData([]);
        setPreview(false);
        if (deletedCount > 0) {
          alert(`${deletedCount} orders deleted successfully.`);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getOrder();
  }, [SDate]);

  const getOrder = async () => {
    try {
      let response = await axios.get(`${endPoints}/api/order/getdata`);
      if (response.status === 200) {
        setOrderData(response.data);
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const handleSelecteRow = state => {
    setSelectedData(state.selectedRows);
  };

  const filterDateswise = data => {
    return data?.filter(item => {
      const createdAtMoment = moment(item?.createdAt);
      const selectedStartDate = SDate.start
        ? moment(SDate.start, "YYYY-MM-DD")
        : null;
      const selectedEndDate = SDate.end
        ? moment(SDate.end, "YYYY-MM-DD")
        : null;

      if (selectedStartDate && selectedEndDate) {
        return createdAtMoment.isBetween(
          selectedStartDate,
          selectedEndDate,
          null,
          "[]"
        );
      }

      if (selectedStartDate) {
        return createdAtMoment.isSameOrAfter(selectedStartDate);
      }

      if (selectedEndDate) {
        return createdAtMoment.isSameOrBefore(selectedEndDate);
      }

      return true;
    });
  };

  const filteredData = filterDateswise(orderData);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (!userData) {
      window.location.assign("/");
    }
  }, []);

  const [viewOrder, setViewOrder] = useState(false);



  return (
    <>
      <Header />

      {viewOrder ? (
        <div className="row p-4">
          <div className="col-md-1 ">
            <img
              width={30}
              onClick={() => setViewOrder(false)}
              style={{ cursor: "pointer" }}
              height={30}
              src="../Assests/icons8-go-back-26.png"
              alt=""
            />
          </div>
          <div className="row">
            <div className="col-md-4"></div>
          </div>
        </div>
      ) : (
        <>
          <div className="row m-auto">
            <div className="col-md-2">
              <label className="fw-bold">Select Date</label>
              <input
                className="col-md-12 mb-2 shadow"
                type="date"
                onChange={handleChange}
                name="start"
              />
            </div>
            <div className="col-md-2">
              <label className="fw-bold">End Date</label>
              <input
                className="col-md-12 mb-2 shadow"
                type="date"
                name="end"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-2 mt-4">

              <Form.Select onChange={handleStatusChange}>
                <option value="">Select Status</option>
                <option value="Processing">Processing</option>
                <option value="Shipped">Shipped</option>
                <option value="Outfor Delivery">Outfor Delivery</option>
                <option value="Delivered">Delivered</option>
              </Form.Select>

            </div>
            <div className="col-md-2 mt-4">
              {SelectedData.length > 0 && (
                <button
                  className="row m-auto p-2"
                  style={{
                    border: 'none',
                    backgroundColor: 'red',
                    color: 'white',
                    borderRadius: '6px',
                  }}
                  onClick={handleUpdateStatus}
                >
                  Update Status
                </button>
              )}
            </div>
            <div className="col-md-2 mt-4">
              {SelectedData?.length > 0 && (
                <button
                  className="row m-auto p-2"
                  style={{
                    border: "none",
                    backgroundColor: "red",
                    color: "white",
                    borderRadius: "6px",
                  }}
                  onClick={() => setPreview(true)}>
                  Delete
                </button>
              )}
            </div>
          </div>

          <DataTable
            title=""
            className="mt-2"
            columns={columns}
            data={filteredData}
            theme="solarized"
            selectableRows
            onSelectedRowsChange={handleSelecteRow}
            pagination={filteredData?.length > 6}
          />
          <Modal
            open={Preview}
            onClose={() => setPreview(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Are you sure you want to delete these orders?
              </Typography>
              <Button onClick={handleDelete} color="error" variant="contained" sx={{ marginTop: "1rem" }}>
                Delete
              </Button>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
}
