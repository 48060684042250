import { Routes, Route, useLocation } from "react-router-dom";
import "./App.css";
import Sidenav1 from "./Component/sidenav";
import Overview from "./Component/Overview";
import Login from "./Component/Login";
import Signup from "./Component/Signup";
import AddProduct from "./Component/AddProduct";
import ProductView from "./Component/ProductView";
import Order from "./Component/Order";
import CouponComponent from "./Component/Coupon";
import PrivateRoute from "./PrivateRoutes";
import PaymentForm from "./PaymentForm";
import OrderView from "./Component/OrderView";
export default function App() {
  const location = useLocation();
  const excludeRoutes = ["/", "/Signup"];
  const shouldRenderSidenav = !excludeRoutes.includes(location.pathname);
  const userData = JSON.parse(localStorage.getItem("userData"));
  return (
    <>
      <div className="App">
        {shouldRenderSidenav && (
          <div className="sidenav-container">
            <Sidenav1 />
          </div>
        )}

        <main>
          <Routes>
            <Route path="/" element={<Login />} />
          </Routes>
          {userData ? (
            <Routes>
              <Route path="/product" element={<AddProduct />} />
              <Route path="/dashboard" element={
                <Overview />
              } />
              <Route path="/viewdetails" element={
                <ProductView />
              } />
              <Route path="/orderview/:data" element={
                <OrderView />
              } />
              <Route path="/order" element={

                <Order />
              } />
              <Route path="/coupon" element={

                <CouponComponent />
              } />
              <Route path="/Signup" element={<Signup />} />
            </Routes>

          ) : (
            <Routes>
              <Route path="/" element={<Login />} />
            </Routes>
          )}
        </main>{" "}
      </div>
    </>
  );
}
