import React, { useState } from "react";
import axios from "axios";
import * as Yup from "yup";

function Login() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});

  const validationSchema = Yup.object({
    email: Yup.string()
      .required("Email is required")
      .email("Invalid email format"),
    password: Yup.string().required("Password is required"),
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Trim whitespace before setting state
    setFormData({ ...formData, [name]: value.trim() });
    setErrors({ ...errors, [name]: "" });
  };

  const handleSubmit = async () => {
    try {
      // Trim values before validation
      const trimmedData = {
        email: formData.email.trim(),
        password: formData.password.trim(),
      };
      await validationSchema.validate(trimmedData, { abortEarly: false });

      const config = {
        url: "/auth/login",
        baseURL: "https://api.gdswellness.com/api",
        method: "post",
        headers: { "Content-type": "application/json" },
        data: trimmedData,
      };

      let response = await axios(config);

      if (response.status === 200) {
        alert("Logged in successfully");
        window.location.assign("dashboard");
        localStorage.setItem("userData", JSON.stringify(response.data || {}));
      }
    } catch (error) {
      const newErrors = {};

      if (error.inner) {
        error.inner.forEach((err) => {
          newErrors[err.path] = err.message;
        });
      }

      setErrors(newErrors);
    }
  };

  return (
    <div className="row m-auto" style={{ height: "100vh" }}>
      <div className="col-md-4 m-auto">
        <div className="form-container">
          <div className="form-input">
            <label htmlFor="email">Email</label>
            <input
              id="email"
              name="email"
              type="text"
              autoComplete="off"
              required
              value={formData.email}
              onChange={handleInputChange}
            />
            {errors.email && <div className="error-text">{errors.email}</div>}
          </div>

          <div className="form-input">
            <label htmlFor="password">Password</label>
            <input
              id="password"
              name="password"
              type="password"
              value={formData.password}
              onChange={handleInputChange}
            />
            {errors.password && <div className="error-text">{errors.password}</div>}
          </div>

          <button onClick={handleSubmit} className="submit-btn">
            Login
          </button>
        </div>
      </div>
    </div>
  );
}

export default Login;
